import { useQuery } from '@tanstack/react-query'
import { Descriptions, Table, Typography } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import { workspaceQueries } from '../../../../Actions'

type Props = {
  workspaceId: number
}

export default function WorkspaceDetails({ workspaceId }: Props) {
  const { data: details } = useQuery({
    ...workspaceQueries.detail(Number(workspaceId)),
  })

  return (
    <>
      {/* <Typography.Title level={3}>Details</Typography.Title> */}
      {details?.members && (
        <div style={{ marginBottom: 20 }}>
          <Typography.Title level={4}>Members</Typography.Title>
          <Table
            dataSource={details.members}
            pagination={false}
            rowKey={(record: any) => record.id}
            columns={[
              {
                title: 'userId',
                dataIndex: 'userId',
                key: 'id',
              },
              {
                title: 'email',
                dataIndex: 'email',
                key: 'email',
                render: (t, r) => <Link to={`/user/${r.userId}`}>{r.email}</Link>,
              },
              {
                title: 'role',
                dataIndex: 'role',
                key: 'role',
              },
            ]}
          />
        </div>
      )}

      {/* Subscription */}
      {details?.subscription && (
        <div style={{ marginBottom: 20 }}>
          {/* <Typography.Title level={4}>
                Subscription
                <Button style={{ marginLeft: 20 }} onClick={() => navigate(`${details?.id}`)}>history</Button>
              </Typography.Title> */}
          <Descriptions column={2} bordered>
            <Descriptions.Item label="Plan">{details.subscription?.plan?.name}</Descriptions.Item>
            <Descriptions.Item label="Status">{details.subscription?.status}</Descriptions.Item>
            <Descriptions.Item label="Started At">{details.subscription?.startedAt}</Descriptions.Item>
            <Descriptions.Item label="Ended At">{details.subscription?.endAt}</Descriptions.Item>
            {/* <Descriptions.Item label="Sub At">
              {moment(details.subscription?.subscribedAt).format('YYYY-MM-DD HH:mm')}
            </Descriptions.Item>
            <Descriptions.Item label="Unsub At">
              {moment(details.subscription?.unsubscribedAt).format('YYYY-MM-DD HH:mm')}
            </Descriptions.Item> */}
            <Descriptions.Item label="Interval">{details.subscription?.intervalMonth}</Descriptions.Item>
            <Descriptions.Item label="Valid To">{details.subscription?.validTo}</Descriptions.Item>
          </Descriptions>
        </div>
      )}
    </>
  )
}
