import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Button, DatePicker, Modal, Select, Space, Typography } from 'antd'
import dayjs from 'dayjs'
// import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { schedulePlans, workspaceQueries } from '../../../../../Actions'
import { getPlans } from '../../../../../Actions/plansApi'

type Props = {
  workspaceId: number
}

export default function AssignPlanButton({ workspaceId }: Props) {
  const [modalOpened, setModalOpened] = useState(false)
  const [message, setMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const { data: plans } = useQuery({
    queryKey: ['plans'],
    queryFn: () => getPlans(),
    select: (data) =>
      data.content.filter((plan: any) => ['PRO-1', 'PRO-2', 'PRO-3', 'BASIC-1', 'BASIC-2'].includes(plan.name)),
  })
  const [assignTime, setAssignTime] = useState(dayjs())
  const [revokeTime, setRevokeTime] = useState(dayjs())
  const [planId, setPlanId] = useState<number | null>(null)

  const { mutateAsync } = useMutation({ mutationFn: schedulePlans })
  const queryClient = useQueryClient()

  const handleOk = async () => {
    try {
      setIsLoading(true)

      if (!planId) {
        setMessage('플랜을 선택해주세요.')
        return
      }

      if (assignTime.isBefore(dayjs().add(5, 'minutes'))) {
        setMessage('부여일시를 현재의 5분 이후로 설정해주세요.')
        return
      }

      if (revokeTime.isBefore(assignTime)) {
        setMessage('회수일시를 부여일시 이후로 설정해주세요.')
        return
      }

      await mutateAsync({
        workspaceId,
        planId,
        assignTime: assignTime.format('YYYY-MM-DDTHH:mm:ss'),
        revokeTime: revokeTime.format('YYYY-MM-DDTHH:mm:ss'),
      })

      queryClient.invalidateQueries({ queryKey: workspaceQueries.detail(workspaceId).queryKey })

      setModalOpened(false)
    } catch (error) {
      if (error instanceof Error) setMessage(error.message)
      // if (error instanceof AxiosError) setModalMessage(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {}, [plans])

  useEffect(() => {
    const proPlanId = plans?.find((plan: any) => plan.name === 'PRO-1')?.id

    if (proPlanId && modalOpened) {
      setPlanId(proPlanId)
      setAssignTime(dayjs().add(10, 'minutes'))
      setRevokeTime(dayjs().add(5, 'years'))
      setMessage('')
    }
  }, [modalOpened, plans])

  return (
    <>
      <Button onClick={() => setModalOpened(true)}>부여하기</Button>
      <Modal
        title="플랜 부여/회수"
        open={modalOpened}
        onOk={handleOk}
        onCancel={() => setModalOpened(false)}
        loading={isLoading}
      >
        <Space direction="vertical">
          <Space direction="vertical" size="middle">
            <Typography.Title level={2} style={{}}>
              부여
            </Typography.Title>

            <Space>
              <Typography.Title level={3}>플랜</Typography.Title>
              <Select
                value={planId}
                size="large"
                onChange={(value) => {
                  setPlanId(value)
                }}
              >
                {plans?.map((plan: any) => <Select.Option value={plan.id}>{plan.name}</Select.Option>)}
              </Select>
            </Space>

            <Typography.Text>
              부여 일시를 지정해주세요. 부여 일시는 현재 시간 기준 최소 5분 이후로 지정해 주세요.
            </Typography.Text>
            <DatePicker
              showTime
              onChange={(value) => {
                setAssignTime(value)
              }}
              // onOk={handleScheduleChanged}
              value={assignTime}
            />
          </Space>

          <Space direction="vertical">
            <Typography.Title level={2}>회수</Typography.Title>

            <Typography.Text italic>
              * 회수 일시는 반드시 지정되어야 합니다.
              <br />
              만약 정해진 일자가 없다면 기본값 그대로 적용하시면 됩니다.
              <br />
              플랜이 회수된 워크스페이스는 상태1로 자동 변경됩니다.
            </Typography.Text>

            <Typography.Text>회수 일시를 지정해주세요.</Typography.Text>
            <DatePicker
              showTime
              onChange={(value) => {
                setRevokeTime(value)
              }}
              // onOk={handleScheduleChanged}
              value={revokeTime}
            />
          </Space>
          {message && <Typography.Text type="danger">{message}</Typography.Text>}
        </Space>
      </Modal>
    </>
  )
}
