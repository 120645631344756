import { useQuery } from '@tanstack/react-query'
import { Typography } from 'antd'
import React from 'react'
import { workspaceQueries } from '../../../../../Actions'
import PlanHistory from './PlanHistory'

type Props = {
  workspaceId: number
}

export default function WorkspacePlan({ workspaceId }: Props) {
  const { data } = useQuery({
    ...workspaceQueries.detail(workspaceId),
  })

  return (
    <div>
      <Typography.Title level={4}>
        플랜: {data?.subscription?.plan?.name}
        {data?.subscription?.status === 'PAUSE' && '(만료)'}
      </Typography.Title>
      <PlanHistory workspaceId={workspaceId} />
    </div>
  )
}
